import React from "react";
import style from './index.module.less'
import useNavigate from "@/hooks/useNavigate";
import useNavigateLink from "@/hooks/useNavigateLink";
import { upgradeBg } from '@/images/experience/index'
import { BlackArrow } from '@/images/index'
import {
  nebulaLogo,
  airLogo,
  lightLogo,
  adaLogo,
  beamLogo,
  proAir2,
  proAir2Ultra,
  proBeamPro,
  UserManual_desktop,
  UserManual_mobile,
  proOnePro,
  proOne,
}
  from '@/images/support/index'
import { useIntl } from "react-intl"
import useGa from "@/hooks/useGa"
import useBigScreen from "@/hooks/bigScreen"
import getCountry from '@/utils/getCountry.js'
import classNames from 'classnames'

const Product = () => {
  const intl = useIntl()
  const ifPc=useBigScreen(768)
  const region = getCountry()
  const ifNoShowOne = region=='th'
  const proList = [
    {
      name: 'One',
      img: proOne,
      link: "/support/one",
      cate: 'support_one'
    },
    {
      name: 'One Pro',
      img: proOnePro,
      link: "/support/one-pro",
      cate: 'support_onepro'
    },
    {
      name: 'Air 2 Ultra',
      img: proAir2Ultra,
      link: "/support/air2ultra",
      cate: 'support_ultra'
    },
    {
      name: intl.formatMessage({ id: "support.product.tab.air2", defaultMessage: "XREAL Air2 Series" }),
      img: proAir2,
      link: "/support/air2",
      cate: 'support_air2'
    },
    {
      name: "Air",
      img: airLogo,
      link: "/support/air",
      cate: 'support_air'
    },
    {
      name: "Light",
      img: lightLogo,
      link: "/support/light",
      cate: 'support_light'
    },
    {
      name: "XREAL Beam Pro",
      img: proBeamPro,
      link: "/support/beampro",
      cate: 'support_beampro'
    },
    {
      name: "Beam",
      img: beamLogo,
      link: "/support/beam",
      cate: 'support_beam'
    },
    {
      name: "Adapter",
      img: adaLogo,
      link: "/support/adapter",
      cate: 'support_adapter'
    },
    {
      name: "Nebula",
      img: nebulaLogo,
      link: "/support/nebula",
      cate: 'support_nebula'
    }
  ] 
  const proList_noone = [

    {
      name: 'Air 2 Ultra',
      img: proAir2Ultra,
      link: "/support/air2ultra",
      cate: 'support_ultra'
    },
    {
      name: intl.formatMessage({ id: "support.product.tab.air2", defaultMessage: "XREAL Air2 Series" }),
      img: proAir2,
      link: "/support/air2",
      cate: 'support_air2'
    },
    {
      name: "Air",
      img: airLogo,
      link: "/support/air",
      cate: 'support_air'
    },
    {
      name: "Light",
      img: lightLogo,
      link: "/support/light",
      cate: 'support_light'
    },
    {
      name: "XREAL Beam Pro",
      img: proBeamPro,
      link: "/support/beampro",
      cate: 'support_beampro'
    },
    {
      name: "Beam",
      img: beamLogo,
      link: "/support/beam",
      cate: 'support_beam'
    },
    {
      name: "Adapter",
      img: adaLogo,
      link: "/support/adapter",
      cate: 'support_adapter'
    },
    {
      name: "Nebula",
      img: nebulaLogo,
      link: "/support/nebula",
      cate: 'support_nebula'
    }
  ] 
  const proList_jp = [
    {
      name: 'One',
      img: proOne,
      link: "/support/one",
      cate: 'support_one'
    },
    {
      name: 'Air 2 Ultra',
      img: proAir2Ultra,
      link: "/support/air2ultra",
      cate: 'support_ultra'
    },
    {
      name: intl.formatMessage({ id: "support.product.tab.air2", defaultMessage: "XREAL Air2 Series" }),
      img: proAir2,
      link: "/support/air2",
      cate: 'support_air2'
    },
    {
      name: "Air",
      img: airLogo,
      link: "/support/air",
      cate: 'support_air'
    },
    {
      name: "Light",
      img: lightLogo,
      link: "/support/light",
      cate: 'support_light'
    },
    {
      name: "XREAL Beam Pro",
      img: proBeamPro,
      link: "/support/beampro",
      cate: 'support_beampro'
    },
    {
      name: "Beam",
      img: beamLogo,
      link: "/support/beam",
      cate: 'support_beam'
    },
    {
      name: "Adapter",
      img: adaLogo,
      link: "/support/adapter",
      cate: 'support_adapter'
    },
    {
      name: "Nebula",
      img: nebulaLogo,
      link: "/support/nebula",
      cate: 'support_nebula'
    }
  ] 
  const proList_show = ifNoShowOne?proList_noone:(region=='jp'||region=='kr'?proList_jp: proList
)

  const getLinkUrl = (link) => {
    const url = useNavigateLink(link)
    return url
  }
  const performAction = (event) => {


    // 防止链接的默认行为 
    event.preventDefault();
    // 执行 href 操作
    // window.location.href = event.currentTarget.getAttribute('href');
    // window.open(event.currentTarget.getAttribute('href'))
    // useGa('support', cate, 'Click')
  }

  return (
    <div className={style.pro}>
      <h2 className={style.proTitle}>
        {intl.formatMessage({ id: "support_product_title", defaultMessage: " Product Support" })}
      </h2>
      <div className={style.proBox} >
        {proList_show.map((item, index) => (
          <div key={`supportItem${index}`} className={classNames(style.proBoxOut,ifNoShowOne&&style.proBoxOutNo,(region=='jp'||region=='kr')&&style.proBoxOutJP)} onClick={() => {
            useGa('support', item.cate, 'Click')
            useNavigate(item.link)
          }}
         
          >
            <a className={style.proBoxItem} onClick={event => event.preventDefault()} href={getLinkUrl(item.link)}>
              <div className={style.proBoxItemImg}>
                <img src={item.img} />
              </div>
              <span>{item.name}</span>
            </a>
          </div>
        ))}
      </div>
      <div className={style.update}>
        <div className={style.updateItem1} style={{ backgroundImage: `url(${upgradeBg})` }}>
          <div className={style.updateContent}>
            <div className={style.updateText} onClick={() => {
              useGa('support', 'support_update', 'Click')
              useNavigate('/support/update')
            }}>
              <a className={style.updateTextBox} href={getLinkUrl('/support/update')} onClick={event => event.preventDefault()}>
                {intl.formatMessage({ id: "support_product_update", defaultMessage: " Firmware Update " })}<BlackArrow />
              </a>
            </div>
          
          </div>
        </div>
        <div className={style.updateItem2} style={{ backgroundImage: `url(${ifPc?UserManual_desktop:UserManual_mobile})` }}>
          <div className={style.updateContent}>
            <div className={style.updateText} onClick={() => {
              useGa('support', 'support_guide', 'Click')
              useNavigate('/support/guide')
            }}>
              <a className={style.updateTextBox} href={getLinkUrl('/support/guide')} onClick={event => event.preventDefault()}>
                {intl.formatMessage({ id: "support_manuals", defaultMessage: "User Manual" })}<BlackArrow />
              </a>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
